import { atom, useAtom, useAtomValue } from 'jotai';

import { useLocalUserFlownUserData } from '/flown/hooks/useFlownUserData';
import { useIsInternalAudioShare } from '/flown/state/custom';
import { useCustomIntegrations } from '/hooks/useCustomIntegrations';
import { useScreens } from '/hooks/useScreens';

import { isMobileAtom } from './core';

/**
 * This file contains UI state pertaining to layout. E.g.: are we on a mobile device or not,
 * which view mode is preferred.
 */

const mobileTrayAtom = atom(true);
/**
 * Returns whether the mobile tray should be shown and a setter to change it.
 */
export const useMobileTray = () => useAtom(mobileTrayAtom);

const showMobileMoreMenuAtom = atom(false);
/**
 * Returns a flag indicating whether to show the More menu in mobile view.
 */
export const useShowMobileMoreMenu = () => useAtom(showMobileMoreMenuAtom);

const showMobilePageMenuAtom = atom(false);
/**
 * Returns a flag indicating whether to show the page menu in mobile view.
 */
export const useShowMobilePageMenu = () => useAtom(showMobilePageMenuAtom);

const pinnedIdAtom = atom<string | null>(null as string);
/**
 * Returns the currently pinned session or screen id and a setter to change it.
 */
export const usePinnedId = () => useAtom(pinnedIdAtom);

export type PreferredViewMode = 'grid' | 'speaker';

const getInitialViewMode = (): PreferredViewMode => {
  if (typeof window === 'undefined') {
    return 'speaker';
  }
  const search = new URLSearchParams(window.location.search);
  return search.get('v') === 'grid' ? 'grid' : 'speaker';
};

const preferredViewModeAtom = atom<PreferredViewMode>(getInitialViewMode());

/**
 * Returns the currently preferred view mode and a setter to change it.
 */
export const usePreferredViewMode = () => useAtom(preferredViewModeAtom);

type ViewMode = PreferredViewMode | 'mobile';

const viewModeAtom = atom<ViewMode>((get) => {
  const isMobile = get(isMobileAtom);
  if (isMobile) return 'mobile';

  const preferredViewMode = get(preferredViewModeAtom);
  return preferredViewMode;
});
/**
 * Returns the current view mode: 'grid', 'speaker' or 'mobile'.
 * In case a participant or screen is pinned or a screen is shared, it will default to 'speaker'.
 */
export const useViewMode = () => {
  const screens = useScreens();
  const viewMode = useAtomValue(viewModeAtom);
  const [pinnedId] = usePinnedId();
  const { runningMainIntegrations } = useCustomIntegrations();
  const isInternalAudioShare = useIsInternalAudioShare();

  const isInternalSharingAudio =
    screens.some((s) => s.screenAudio.state !== 'off') && isInternalAudioShare;

  const userData = useLocalUserFlownUserData();
  const isHost = !!userData?.isHost;

  /**
   * If someone is screensharing we always want the speaker view
   */
  const isScreenSharing = screens.some((s) => s.screenVideo.state !== 'off');
  if (isScreenSharing) return 'speaker';

  // If the user is the host, we always follow their preferred view mode
  if (isHost) return viewMode;

  /**
   * Particpants always see the speaker view when someone is pinned
   */
  if (pinnedId) return 'speaker';

  if (
    ((screens.length + runningMainIntegrations.length > 0 &&
      viewMode !== 'mobile') ||
      pinnedId) &&
    !isInternalSharingAudio
  )
    return 'speaker';
  return viewMode;
};
