import {
  useLocalSessionId,
  useParticipantProperty,
} from '@daily-co/daily-react';

import { FlownData, SessionUser } from '/flown/utils/user-data';

const TEST_USER: SessionUser = {
  firstName: 'Flown',
  lastName: 'User',
  displayName: 'Flown User',
  avatarUrl:
    'https://res.cloudinary.com/dib8pjpfy/image/upload/c_thumb,g_auto:face,h_200,w_200,z_0.5/v1675091380/user-profiles/ucdg5mazc3ch26yhzlng.png',
  externalId: 'flown-user',
  oneLiner: 'Flown User',
  location: 'Flown',
  userRole: 'flown',
  flownId: 'flown-user',
  dailyId: 'flown-user',
  sessionsAttended: 0,
  isOriginalHost: false,
  isHost: false,
  isFlownUser: true,
  title: 'Badge',
  description: 'Badge',
  slug: 'badge',
  illustrationUrl: '/image.png',
  illustrationAlt: 'badge',
  totalSessions: 0,
};

type UseFlownUserDataProps = {
  sessionId: string;
};
/**
 * userDataProp might be null. In a production setting, this is incredibly unlikely to happen, but in development mode,
 * React re-renders twice on purpose in order to expose these edge cases to you.
 * https://react.dev/reference/react/StrictMode#fixing-bugs-found-by-double-rendering-in-
 * Note that the badge data is flattened by flown-com so it can be passed directly to the Badge component.
 */
export const useFlownUserData = ({
  sessionId,
}: UseFlownUserDataProps): (SessionUser & { isFlownUser: boolean }) | null => {
  const userDataProp = useParticipantProperty(sessionId, 'userData');
  const userData = userDataProp ? (userDataProp as FlownData).c : null;

  if (process.env.NEXT_PUBLIC_IS_LOCAL_DEV) {
    return TEST_USER;
  }

  return userData
    ? {
        ...userData,
        isFlownUser: userData.userRole === 'flown',
      }
    : null;
};

export const useLocalUserFlownUserData = () => {
  const localSessionId = useLocalSessionId();
  const userData = useFlownUserData({ sessionId: localSessionId });

  return userData;
};
