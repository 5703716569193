import { SidebarView } from '@daily-co/daily-js';
import { atom, useAtom } from 'jotai';

/**
 * General UI state.
 * These are general purpose shared UI states.
 */

/**
 * Mobile state.
 * In case the user browses with a mobile device, this state enforces mobile UI
 * and overall mobile adjustments (like increased font-size and icons).
 */
export const isMobileAtom = atom(false);
/**
 * Returns whether this call is in mobile mode and a setter to change it.
 */
export const useIsMobile = () => useAtom(isMobileAtom);

const sidebarViewAtom = atom<SidebarView | null>(null as SidebarView);
/**
 * Used to control visibility and tabs for Sidebar.
 */
export const useSidebarView = () => useAtom(sidebarViewAtom);

const attendeeInteractedWithUIAtom = atom(false);
/**
 * Returns a flag indicating whether a broadcast attendee already interacted with the UI.
 */
export const useAttendeeInteractedWithUI = () =>
  useAtom(attendeeInteractedWithUIAtom);
