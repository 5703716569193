import { useTheme } from '@daily/shared/contexts/Theme';
import getConfig from 'next/config';
import Head from 'next/head';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useCallState } from '/contexts/CallProvider';
import { useIsMobile } from '/lib/state/core';

export const Meta: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation();
  const callState = useCallState();
  const { assetPrefix } = getConfig().publicRuntimeConfig;
  const { colors } = useTheme();
  const [isMobile] = useIsMobile();

  const title = useMemo(() => {
    switch (callState?.state) {
      case 'awaiting-args':
      case 'joining':
      case 'lobby':
        return t('meta.title.idle', {
          defaultValue: 'Daily | Get ready for your call',
          fallbackLng: 'en',
        });
      case 'joined':
        return t('meta.title.joined', {
          defaultValue: "Daily | You're in a call",
          fallbackLng: 'en',
        });
      case 'ended':
        return t('meta.title.ended', {
          defaultValue: 'Daily | The meeting has ended',
          fallbackLng: 'en',
        });
      case 'error':
        return t('meta.title.error', {
          defaultValue: 'Daily | Something went wrong',
          fallbackLng: 'en',
        });
      case 'expired':
        return t('meta.title.expired', {
          defaultValue: 'Daily | This meeting is no longer available',
          fallbackLng: 'en',
        });
      case 'full':
        return t('meta.title.full', {
          defaultValue: 'Daily | The meeting is full',
          fallbackLng: 'en',
        });
      case 'left':
        return t('meta.title.left', {
          defaultValue: 'Daily | You have left the call',
          fallbackLng: 'en',
        });
      case 'nbf':
        return t('meta.title.nbf', {
          defaultValue: 'Daily | This meeting is not available yet',
          fallbackLng: 'en',
        });
      case 'not-allowed':
        return t('meta.title.notAllowed', {
          defaultValue: 'Daily | You are not allowed to join this meeting',
          fallbackLng: 'en',
        });
      case 'not-found':
        return t('meta.title.notFound', {
          defaultValue:
            "Daily | The meeting you're trying to join does not exist",
          fallbackLng: 'en',
        });
      case 'not-secure':
        return t('meta.title.notSecure', {
          defaultValue: 'Daily | Could not connect using HTTP',
          fallbackLng: 'en',
        });
      case 'removed-from-call':
        return t('meta.title.removed', {
          defaultValue: 'Daily | You were removed from the call',
          fallbackLng: 'en',
        });
    }
  }, [callState?.state, t]);

  return (
    <Head>
      <title>{title}</title>
      <meta name="robots" content="noindex" />
      <meta
        property="og:title"
        content={t('meta.og.title', {
          defaultValue: 'Join my video call',
          fallbackLng: 'en',
        })}
      />
      <meta property="og:type" content="Website" />
      <meta property="og:site_name" content="Daily" />
      <meta
        property="og:description"
        content={t('meta.og.description', {
          defaultValue: 'Just click to join this call.',
          fallbackLng: 'en',
        })}
      />
      <meta property="twitter:card" content="summary" />
      <meta property="twitter:site" content="trydaily" />
      <meta
        property="twitter:title"
        content={t('meta.og.title', {
          defaultValue: 'Join my video call',
          fallbackLng: 'en',
        })}
      />
      <meta
        property="twitter:description"
        content={t('meta.og.description', {
          defaultValue: 'Just click to join this call.',
          fallbackLng: 'en',
        })}
      />
      <meta name="color-scheme" content="dark light" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={`${assetPrefix}/apple-touch-icon.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={`${assetPrefix}/favicon-16x16.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={`${assetPrefix}/favicon-32x32.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="192x192"
        href={`${assetPrefix}/favicon-192x192.png`}
      />
      <link rel="manifest" href={`${assetPrefix}/site.webmanifest`} />
      <link
        rel="mask-icon"
        href={`${assetPrefix}/safari-pinned-tab.svg`}
        color="#5bbad5"
      />
      <meta
        name="msapplication-TileColor"
        content={`${assetPrefix}/favicon-270x270.png`}
      />
      <meta
        name="msapplication-config"
        content={`${assetPrefix}/browserconfig.xml`}
      />
      <meta
        name="theme-color"
        content={
          isMobile
            ? callState?.state === 'lobby'
              ? colors.background
              : colors.custom.mainAreaBg
            : colors.background
        }
      />
    </Head>
  );
};
