import { DailyCustomTrayButtons } from '@daily-co/daily-js';
import { atom, useAtom } from 'jotai';

/**
 * This file contains UI state that can managed from a call frame instance.
 * I.e. state that can be changed through daily-js frame methods
 */

const customTrayButtonsAtom = atom<DailyCustomTrayButtons>({});
/**
 * Returns Array of custom tray buttons and a setter to change them.
 */
export const useCustomTrayButtons = () => useAtom(customTrayButtonsAtom);

/**
 * Call configuration states.
 */
const maxGridTilesPerPageAtom = atom(24);
/**
 * Returns the maximum amount of tiles per page in grid view and a setter to change it.
 */
export const useMaxGridTilesPerPage = () => useAtom(maxGridTilesPerPageAtom);

const minGridTilesPerPageAtom = atom(4);
/**
 * Returns the minimum amount of tiles per page in grid view and a setter to change it.
 */
export const useMinGridTilesPerPage = () => useAtom(minGridTilesPerPageAtom);

const showLocalVideoAtom = atom(true);
/**
 * Returns a flag indicating whether the call is configured to show the local user's view
 * and a setter to change it.
 */
export const useShowLocalVideo = () => useAtom(showLocalVideoAtom);

const showParticipantsBarAtom = atom(true);
/**
 * Returns a flag indicating whether to show the ParticipantBar in SpeakerView.
 */
export const useShowParticipantsBar = () => useAtom(showParticipantsBarAtom);

const showNamesAtom = atom(true);
/**
 * Returns a flag indicating whether to show names on video tiles.
 */
export const useShowNames = () => useAtom(showNamesAtom);

const enableJoinSoundAtom = atom(true);
/**
 * Returns a flag indicating whether to play a join sound.
 */
export const useEnableJoinSound = () => useAtom(enableJoinSoundAtom);

const isFullscreenAtom = atom(false);
/**
 * Returns a flag indicating whether the call is in fullscreen mode.
 */
export const useIsFullscreen = () => useAtom(isFullscreenAtom);

const showLeaveButtonAtom = atom(false);
/**
 * Returns a flag indicating whether a leave button should be displayed.
 */
export const useShowLeaveButton = () => useAtom(showLeaveButtonAtom);

const showFullscreenButtonAtom = atom(false);
/**
 * Returns a flag indicating whether a fullscreen button should be displayed.
 */
export const useShowFullscreenButton = () => useAtom(showFullscreenButtonAtom);

const showUserNameChangeUIAtom = atom(true);
/**
 * Returns a flag indicating whether to show the Username change UI.
 */
export const useShowUserNameChangeUI = () => useAtom(showUserNameChangeUIAtom);
