import classnames from 'classnames';
import React from 'react';

import { useCardContext } from './Card';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  footer?: boolean;
  header?: boolean;
  href?: string;
  HrefComponent?: React.FC<React.PropsWithChildren<any>>;
  noDivider?: boolean;
  noPadding?: boolean;
}

export const CardContent: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  className,
  footer = false,
  header = false,
  HrefComponent = null,
  noDivider = false,
  noPadding = false,
  ...props
}) => {
  const { columns } = useCardContext();

  const El = HrefComponent || (footer ? 'footer' : 'div');
  return (
    <El
      className={classnames('card-content', className, {
        footer,
        header,
        noDivider,
        noPadding,
      })}
      {...props}
    >
      {children}
      <style jsx>{`
        .card-content {
          box-shadow: 1px 0 0 var(--card-border), 0 1px 0 var(--card-border);
          ${columns > 1 ? `flex-basis: ${100 / columns}%;` : ''}
          ${columns > 1 ? 'flex-grow: 1;' : ''}
          flex-shrink: 0;
          max-width: 100%;
          min-width: calc(100% / var(--card-columns));
          padding: var(--card-spacing);
        }
        /* Source: https://notestoself.dev/posts/css-select-last-grid-row/ */
        .card-content:nth-child(${columns}n+1):nth-last-child(-n
            + ${columns}):not(:last-child),
        .card-content:nth-child(${columns}n+1):nth-last-child(-n + ${columns})
          ~ .card-content:not(:last-child) {
          box-shadow: 1px 0 0 var(--card-border);
        }
        .card-content:last-child {
          box-shadow: none;
        }
        .card-content.noPadding {
          padding: 0;
        }
        .header {
          background-color: var(--card-header-bg);
        }
        .footer {
          background-color: var(--card-footer-bg);
        }
        .header,
        .footer {
          max-width: none;
          min-width: 100%;
        }
        .footer {
          border-top: 1px solid var(--card-border);
        }
        a.card-content:focus-visible,
        a.card-content:hover {
          background-color: var(--card-bg-hover);
          border-radius: 0;
          cursor: pointer;
          outline: none;
        }
      `}</style>
    </El>
  );
};
