import {
  DailyParticipant,
  DailyParticipantsObject,
  DailyTrackState,
} from '@daily-co/daily-js';

import { CUSTOMER_USER_DATA_KEY } from './userData';

// This function massages the participant object to remove the MediaStreamTracks
// and manipulate userData to match the client set userData (which internally
// is nested under CUSTOMER_USER_DATA_KEY ('c') so as not to interfere with prebuilt's
// usage) Note: doesn't actually delete the tracks fields, just marks them undefined,
// for code simplicity.
export function cloneParticipantAndMungeForIframeProvider(
  participant: DailyParticipant
): DailyParticipant {
  let participantClone = {
    ...participant,
    audioTrack: undefined,
    videoTrack: undefined,
    screenVideoTrack: undefined,
    screenAudioTrack: undefined,
    tracks: {
      audio: {
        ...participant.tracks.audio,
        track: undefined,
        persistentTrack: undefined,
      },
      video: {
        ...participant.tracks.video,
        track: undefined,
        persistentTrack: undefined,
      },
      screenAudio: {
        ...participant.tracks.screenAudio,
        track: undefined,
        persistentTrack: undefined,
      },
      screenVideo: {
        ...participant.tracks.screenVideo,
        track: undefined,
        persistentTrack: undefined,
      },
    },
  };
  if (participant.userData !== undefined) {
    // The spec says that `userData` is not in the participants object if it is undefined
    if (participant.userData[CUSTOMER_USER_DATA_KEY] === undefined) {
      delete participantClone.userData;
    } else {
      participantClone.userData = participant.userData[CUSTOMER_USER_DATA_KEY];
    }
  }
  return participantClone;
}

export function cloneParticipantsAndMungeForIframeProvider(
  participants: DailyParticipantsObject
): DailyParticipantsObject {
  const newParticipants = {};
  for (const id in participants) {
    newParticipants[id] = cloneParticipantAndMungeForIframeProvider(
      participants[id]
    );
  }
  return newParticipants as DailyParticipantsObject;
}

/**
 * Determines whether a track is considered as off.
 * @param p The participant object to check.
 * @param type The track type. Must match a key of a participant's 'tracks' object.
 * @returns true in case the track is in a 'blocked' or 'off' state.
 */
export function isParticipantTrackOff(
  p: DailyParticipant,
  type: keyof DailyParticipant['tracks']
) {
  const trackState = p?.tracks[type];
  if (!trackState) return true;
  return trackState.state === 'blocked' || trackState.state === 'off';
}

export function isTrackOff(trackState: DailyTrackState['state']) {
  return trackState === 'blocked' || trackState === 'off';
}
